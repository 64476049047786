module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B&W Damp and Timber","short_name":"bandw","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bwpropertycare","defaultLang":"en-gb","accessToken":"MC5YUlB4OHhBQUFDSUFvVlJw.Ru-_vSXvv71GKu-_ve-_vXzvv70Nak7vv70n77-977-9ZO-_vTFz77-977-9EQDvv70lPi9P77-977-9","previews":true,"path":"/preview","pages":[{"type":"Services","match":"/:uid","path":"/service-preview","component":"/opt/build/repo/src/templates/services.js"},{"type":"Legal","match":"/:uid","path":"/legal-preview","component":"/opt/build/repo/src/templates/legalPage.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bwpropertycare.co.uk","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
